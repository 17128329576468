.sapp-select .ant-select-selection-item {
  padding-inline-end: 0px !important;
}

.sapp-select .ant-select-selector {
  background-color: var(--bs-gray-100) !important;
  border: none !important;
  border-radius: 6px !important;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item-remove {
  padding-right: 8px !important;
}
