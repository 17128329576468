.scrolltop {
  border-radius: 50%;
  display: flex;
  transition: opacity 0.3s ease-in-out;
}

.scrolltop.visible {
  opacity: 0.3;
}

.scrolltop:hover {
  opacity: 1;
}
