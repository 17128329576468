.sapp-your-answer {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 0.125rem;
  display: inline-block;
  margin-left: 12px;
  color: #404041;
  padding: 1px 8px;
  border: 1px solid #dcdddd;
}
