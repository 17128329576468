.grade-solution-popup {
  .moveable-resizable {
    box-shadow: 0px 8px 48px 0px #00000029;
    z-index: 50;
  }

  .solution-content {
    padding: 16px 24px;
    overflow-y: auto;
  }

  .solution-content-title {
    color: #141414;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}
