.sapp-preview-activity {
  --primary-color: #ffb800;
  --text-color-primary: #141414;
  --text-color-2nd: #a1a1a1;
  --warning-color: #d35563;
  --correct-color: #008000;
  --primary-color-2: #faaf00;
  --font-family-base: 'Roboto';
  --bs-success-inverse: #141414;
  color: var(--text-color-primary);
  // font-size: 16px;
  font-family: var(--font-family-base);

  .tab-header ul li {
    list-style: none;
  }
  .mt-2 {
    margin-top: 8px !important;
  }
  .fw-semibold {
    font-weight: 600 !important;
  }
  .color-2nd {
    color: var(--text-color-2nd);
  }

  .mw-950px {
    min-width: 950px !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .mb-12px {
    margin-bottom: 12px;
  }

  .mb-32px {
    margin-bottom: 32px;
  }

  .mr-12px {
    margin-right: 12px;
  }

  .column-gap-15px {
    column-gap: 15px;
  }

  .container {
    max-width: 1144px;
    margin: 0 auto;
  }

  .container-fluid {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .text-danger {
    color: var(--warning-color);
  }

  .header .title > .title-right {
    white-space: nowrap;
    font-size: 14px;
    line-height: 16.9px !important;
    margin-top: 7px;
  }

  .tab {
    margin-top: -37px;
  }

  .tab-header ul {
    gap: 8px;
    margin-left: 24px;
  }

  .tab-header li {
    padding: 10px 12px;
    line-height: 16.94px;
    font-weight: 400;
    color: var(--text-color-2nd);
    cursor: pointer;
    background-color: #fff;
    font-size: 14px;
  }
  .tab-header li:hover {
    color: var(--text-color-2nd);
  }
  .tab-header li:hover {
    color: var(--text-color-2nd);
  }

  .tab-header li._active {
    color: #fff;
    background-color: var(--primary-color);
  }
  .tab-header li._active:hover {
    color: #fff;
    background-color: var(--bs-primary-active) !important;
  }

  .tab-content {
    max-width: 980px;
    margin: 0 auto;
    padding: 24px 30px;
    box-sizing: border-box;
    overflow: auto;
  }

  .tab-footer {
    max-width: 980px;
    margin: 0 auto;
    padding: 24px 30px 32px 30px;
    border-top: 1px solid #e5e5e5;
  }

  .tab-footer,
  .tab-footer .previous,
  .tab-footer .next {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tab-footer .previous,
  .tab-footer .next {
    column-gap: 8px;
    font-weight: 600;
    line-height: 16.9px;
    color: var(--text-color-primary);
    cursor: pointer;
    position: relative;
    z-index: 2;
  }

  .tab-footer .previous::before,
  .tab-footer .next::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 100%;
    bottom: 0;
    left: 50%;
    background-color: #f1f1f1;
    transform: translate(-50%, -18%);
    z-index: 0;
    padding: 0 1px;
    box-sizing: content-box;
  }

  .tab-footer .arrow {
    display: flex;
    align-items: center;
    z-index: 2;
  }
  .tab-footer .text {
    z-index: 2;
  }

  .question {
    border: 1px solid #dcdddd;
    max-height: 819px;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 50px;
  }

  .question .question-main {
    outline: none;
    padding: 24px;
    width: calc(100% + 24px);
    box-sizing: border-box;
  }

  .question::-webkit-scrollbar {
    width: 22.5px;
  }

  .question::-webkit-scrollbar-thumb {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    background-clip: padding-box;
    border-radius: 11px;
    background-color: #efefef;
  }

  .question::-webkit-scrollbar-thumb:hover {
    background-color: #f4f4f4;
  }

  .question-footer {
    height: 50px;
    background-color: #dcdddd;
  }

  .question-footer {
    padding: 0 24px;
  }

  .question-footer .paginate {
    column-gap: 16px;
  }

  .question-footer .next-button {
    width: 95px;
    height: 32px;
    color: #fff;
    // font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--text-color-2nd);
    cursor: pointer;
  }

  .question-footer .graded-activity-button {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #555eb1;
    background-color: #e3e4ee;
    padding: 3px 7px;
    cursor: pointer;
  }

  .header {
    background-color: #ededed;
    padding-bottom: 37px;
  }

  .title {
    padding: 25px 0;
    border-bottom: 1px solid #d0d0d0;
  }

  .title .title-left {
    font-size: 20px;
  }

  .learning-outcome {
    padding: 25px 0 13px 0;
  }

  .learning-outcome .learning-outcome-ul {
    margin-top: 8px;
    padding-left: 15px;
  }

  .learning-outcome .learning-outcome-ul li {
    margin-top: 8px;
    line-height: 33px;
    list-style-type: disc;
  }

  .header {
    background-color: #ededed;
    padding-bottom: 37px;
  }

  .title {
    padding: 25px 0;
    border-bottom: 1px solid #d0d0d0;
  }

  .radio-group,
  .radio-item {
    display: flex;
    align-items: center;
  }

  .radio-group {
    column-gap: 40px;
    row-gap: 15px;
    font-size: 16px;
    line-height: 19.36px;
  }

  .radio-item {
    gap: 15px;
    cursor: pointer;
    color: #a1a1a1;
  }

  .radio-group input[type='radio'] {
    position: relative;
    cursor: pointer;
  }

  .radio-group input[type='radio']::before {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid var(--text-color-2nd);
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }

  .radio-group input[type='radio']:checked:after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--text-color-primary);
  }

  .radio-group input[type='radio']:checked::before {
    border: 2px solid var(--text-color-primary);
  }

  .radio-group input[type='radio']:checked ~ .radio-item-label {
    color: var(--text-color-primary);
  }

  .radio-group input[type='radio'].correct:checked::before {
    border: 2px solid var(--correct-color);
  }

  .radio-group input[type='radio'].correct:checked ~ .radio-item-label {
    color: var(--correct-color);
  }

  .radio-group input[type='radio'].correct:checked:after {
    background-color: var(--correct-color);
  }

  .radio-group input[type='radio'].warning:checked::before {
    border: 2px solid var(--warning-color);
  }

  .radio-group input[type='radio'].warning:checked ~ .radio-item-label {
    color: var(--warning-color);
  }

  .radio-group input[type='radio'].warning:checked:after {
    background-color: var(--warning-color);
  }

  .question input[type='radio']::before {
    border: 2px solid var(--text-color-primary);
  }

  .answer-wrapper {
    padding-top: 4px !important;
    grid-row-gap: 20px !important;
    row-gap: 20px !important;
  }

  .dropdown .dropdown-toggle {
    background: none !important;
    color: var(--text-color-primary);
    font-weight: 600;
    border: none !important;
    padding: 0 !important;
    font-size: 16px !important;
    &:hover {
      background-color: #fff !important;
    }
  }
  .dropdown-menu {
    min-width: max-content;
  }
  .dropdown-item {
    line-height: 24px;
    min-width: 150px;
    padding: 6.5px 12px;
    // overflow: hidden;

    white-space: nowrap;
    // max-width: 300px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item:hover {
    background-color: rgba(255, 187, 0, 0.0705882353) !important;
    transition: color 0.2s ease;
    color: var(--bs-primary) !important;
    font-weight: 500;
  }
  .dropdown {
    .btn-check:checked + .btn.btn-success,
    .btn-check:active + .btn.btn-success,
    .btn.btn-success:focus:not(.btn-active),
    .btn.btn-success:hover:not(.btn-active),
    .btn.btn-success:active:not(.btn-active),
    .btn.btn-success.active,
    .btn.btn-success.show,
    .show > .btn.btn-success {
      color: #141414;
      border: none !important;
      background: none !important;
    }
  }

  .sapp-border-gray-3 {
    border-color: #f1f1f1 !important;
  }
  .sapp-upload-button {
    border: 1px solid #f1f1f1;
    width: fit-content;
    padding: 1px 8px 1px 5px;
    background-color: #f9f9f9;
    cursor: pointer;
    font-size: 12px;
    line-height: 24px;
    color: #141414;
    margin-top: 9px;
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    margin-bottom: 14.25px;
  }
  .p-12px {
    padding: 12px;
  }
  .requirement-menu {
    width: 100%;
    max-width: 300px;
    margin-top: 0 !important;
  }

  .seprate-line-preview {
    display: none !important;
  }

  .sapp-videojs-question {
    height: 100%;
    overflow: hidden;
  }
  .sapp-question-warper {
    width: calc(100% - 200px);
    height: calc(100% - 88px);
    margin: 0 auto;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  .sapp-question-warper > div {
    // padding: 20px 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    border-bottom: 1px solid #dcdddd;
    flex: 0 0 auto;
  }
  .sapp-question-content-warper {
    .sapp-preview-exhibits {
      padding: 24px 0 14px 0 !important;
    }
    .sapp-preview-requirement {
      padding-top: 24px !important;
      & > div:first-child {
        margin-bottom: 24px !important;
      }
    }
    .fw-semibold.mt-3 {
      margin-top: 11px !important;
    }
  }
  .sapp-videojs-question .sapp-question-content-warper {
    height: 100%;
  }
  .sapp-videojs-question .sapp-question-content-warper .question {
    height: 100% !important;
  }
  .sapp-question-warper-header,
  .sapp-question-warper-footer {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    flex: 0 0 auto;
  }
  .sapp-question-warper-header {
    border-bottom: 1px solid #dcdddd;
    padding: 20px 24px;
  }
  .sapp-question-warper-footer {
    border-top: 1px solid #dcdddd;
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  .sapp-opacity-80 {
    opacity: 80%;
  }
  .sapp-video-header-timeline .dropdown-toggle {
    color: var(--primary-color) !important;
    &:hover {
      color: var(--primary-color) !important;
    }
  }
  .sapp-video-header-timeline .dropdown-menu > a {
    width: 412px;
    max-width: 100%;
  }

  .sapp-video-header-timeline .dropdown-toggle::after {
    display: none;
  }

  .sapp-video-header-timeline-dropdown {
    overflow: hidden;
    & > div {
      div:first-child {
        color: #3964ea;
        margin-right: 12px;
        min-width: 55px;
        text-align: center;
      }
      div:last-child {
        flex: 1;
        white-space: nowrap; /* Không cho văn bản xuống dòng */
        overflow: hidden; /* Ẩn văn bản vượt quá kích thước khối */
        text-overflow: ellipsis; /* Thay thế văn bản bị ẩn bằng dấu ba chấm */
      }
    }
  }
  .questions {
    margin-bottom: 24px !important;
    .sapp-preview-requirement {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }
    .sapp-preview-exhibits {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
    }
  }
  .sapp-requirement-description {
    margin-top: 6px !important;
  }
  .upload-file-preview {
    padding: 10px 0 14px 0 !important;
    display: block;
    .title-upload-button-preview {
      margin-bottom: 8px;
    }
    .upload-button-preview {
      padding-left: 0 !important;
      svg {
        padding: 6px 0 6px 8px !important;
        display: block;
        box-sizing: content-box;
      }
    }
  }
}
.sapp-exhibit-close-button {
  top: 12px;
  right: 12px;
  z-index: 99999;
}
.sapp-preview-activity_modal .modal-content {
  border-radius: 0 !important;
}
.sapp-preview-activity_quiz-modal .modal-header .btn-close {
  display: none !important;
}

.sapp-modal-preview-question-header {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.sapp-modal-preview-primary-button,
.sapp-modal-preview-secondary-button {
  size: 14px !important;
  line-height: 16.9px !important;
  font-weight: 600 !important;
  text-align: center !important;
  border: none !important;
  outline: none !important;
  padding: 9px 16px !important;
  min-width: 80px;
  border-radius: 0;
  margin: 0;
}
.sapp-modal-preview-primary-button {
  margin-left: calc(12px - calc(var(--bs-modal-footer-gap) * 0.5));
}
.sapp-modal-preview-secondary-button {
  background-color: #f1f1f1 !important;
  color: var(--text-color-primary);
}

.sapp-preview-activity_quiz-modal .modal-header .btn-close {
  display: none !important;
}

.invisible {
  visibility: hidden;
}
