.sapp-certificate-taskbar_checkbox {
  position: relative;
  .form-check-custom {
    margin-bottom: 0 !important;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: 0;
    .form-check-input {
      cursor: pointer;
      height: 100%;
      width: 100%;
    }
  }
}
