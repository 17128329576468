@import '../../../styles/index.scss';

.sapp-hook-form-select.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
  &:not(.ant-select-status-error):hover > .ant-select-selector {
    box-shadow: none;
    border-color: var(--bs-gray-300);
  }
}

.ant-select-item {
  &.ant-select-item-option {
    &.ant-select-item-option-active,
    &.ant-select-item-option-selected {
      background-color: #ffffff;
      transition: color 0.2s ease;
      color: var(--bs-primary);
      font-weight: 500;
    }
  }

  &.ant-select-item-option {
    border-radius: 0;
  }
}

.ant-select-dropdown {
  padding: 0;
  margin: 0;
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):not(.ant-select-status-error)
  .ant-select-selector {
  border-color: var(--bs-primary) !important;
  box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--bs-gray-300);
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(241, 76, 65, 0.25);
}

.ant-skeleton.ant-skeleton-element .ant-skeleton-input {
  min-width: 100px;
}

.ant-select-item-option {
  &:hover {
    background-color: rgba(255, 187, 0, 0.0705882353) !important;
    transition: color 0.2s ease;
    color: var(--bs-primary) !important;
  }
}

.ant-select-item-option-active {
  background-color: transparent !important;
}

.ant-select-item-option {
  padding-top: 0.5rem !important;
  padding-bottom: 0.55rem !important;
  // margin: 0.3rem !important;
}

.hookform-multi-select .ant-select-selector {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}

.hookform-multi-select .ant-select-selection-search {
  margin-inline-start: 0px !important;
}

.ant-select-item-option-state {
  color: var(--bs-primary) !important;
}

.ant-select-selector {
  padding: 0px 35px 0px 10px !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  color: var(--bs-gray-700) !important;
  font-family: Inter, Helvetica, 'sans-serif' !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-select-selection-placeholder {
  color: var(--bs-gray-500) !important;
  line-height: 20px !important;
  font-size: 14px !important;
  border-radius: 6px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 21px !important;
  font-size: 14px;
  color: var(--bs-gray-800);
}

.ant-select-selection-item {
  font-weight: 500 !important;
  padding-inline-end: 0px !important;
}

.rc-virtual-list-scrollbar-thumb {
  width: 0.3rem !important;
}

// .sapp-h-45px.ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
//   .ant-select-selector {
//     height: 45px;
//     .ant-select-selection-item {
//       height: 45px;
//       line-height: 45px !important;
//     }
//   }
//   .ant-select-selection-search-input {
//     height: 43px;
//     line-height: 43px;
//   }
//   .ant-select-selection-placeholder {
//     line-height: 45px;
//   }
// }

.ant-select .ant-select-clear {
  width: 20px;
}

.ant-select-focused .ant-select-selector {
  border-color: var(--bs-primary) !important;
}
