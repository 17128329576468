.sapp-certificate-taskbar_select {
  position: relative;
  .ant-select {
    width: 32px !important;
    height: 32px !important;
    max-width: 32px !important;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: 0;
  }
}
