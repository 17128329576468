.grade-recomendation {
  .moveable-resizable {
    box-shadow: 0px 8px 13px 0px #00000021, 0px 0px 4px 0px #0000000a;
    z-index: 50;
  }

  label {
    line-height: 16.9px;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 0;
  }

  .resizable.moveable-resizable textarea {
    border-radius: 0;
    cursor: auto;
  }

  .recomendation-footer {
    border-top: 1px solid #dcdddd;
    padding-top: 8px;
    padding-bottom: 8px;

    .btn-cancel {
      padding: 8px 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-decoration: underline;
    }

    .btn-primary {
      padding: 7px 30px;
      border-radius: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
