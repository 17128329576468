.sapp-created-content-name {
  color: var(--bs-gray-800);
  // font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.99px; /* 114.214% */
}
.sapp-custom-bg {
  background-color: transparent !important;
}
// .sapp-created-container {
//     min-height: 48px;
// }

.sapp-px-24px {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.sapp-py-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.sapp-mt-24px {
  margin-top: 24px;
}

.btn.btn-icon-gray-400 i {
  color: #99a1b7;
}

.sapp-mb-24px {
  margin-bottom: 24px;
}

.sapp-cursor-move {
  cursor: move;
}
