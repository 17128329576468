.sapp-m-h-unset {
  max-height: unset;
}

.sapp-add-learn {
  width: 100%;
  padding: 14px;
  color: #7e8299;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
  border-radius: 8px;
  border: 1px solid #e1e3ea;
  cursor: pointer;
}

.sapp-header-outcome {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sapp-selected-learning-container {
  border: 1px solid #ececec;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sapp-title-checkbox-activity {
  color: var(--text-color-2nd, #a1a1a1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.99px;
  /* 114.214% */
}

.sapp-modal-createTab.modal-fullscreen.modal-fullscreen-form {
  .sapp-tabs-nav-container {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--bs-border-color);
    width: 100%;
  }
  .modal-header {
    border-bottom: none;
  }
}

.sapp-modal-createTab.modal-fullscreen.modal-fullscreen-form.modal-tabs .modal-header {
  padding: 0 !important;
  .btn-icon_close {
    position: relative;
    right: calc(1.75rem);
    top: 19.5px;
  }
}

.modal-fullscreen.modal-fullscreen-form.modal-tabs .modal-header {
  max-width: 1150px;
}

.sapp-tabs-nav-container {
  max-width: calc(1200px - 3.5rem);
  margin: auto;
  width: 100%;
}

.sapp-select-custom {
  .ant-select-selector {
    height: 100% !important;
  }
  .ant-select-selection-item .ant-select-selection-item-content {
    white-space: nowrap;
    max-width: 300px;
  }
}

.sapp-pt-32px {
  padding-top: 32px;
}

.sapp-mw-900px {
  max-width: 900px !important;
}

.sapp-p-24px {
  padding: 24px;
}
