//
// Header
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.app-header {
		.app-navbar {
			.header-search {
				.search-input {
					@include placeholder($gray-500);
					background-color: transparent;	
					box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.03);
				}	
	
				.search-icon {
					color: $gray-600;
				}
			}
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.app-header {
		.page-entry {
			display: none !important;
		}
	}
}	