.sapp-answer-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  padding-top: 0.5rem;
}

.small {
  width: 18px;
  height: 18px;
}

.medium {
  width: 24px;
  height: 24px;
}

.lager {
  width: 30px;
  height: 30px;
}

.sapp-answer {
  flex: 1;
}

.sapp-answer-item {
  flex: 1;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #404041;
  display: flex;
  align-items: center;
}

.sapp-questions {
  font-size: 16px;
  line-height: 24px;
  color: #404041;
}

.text-state-error {
  color: #b90e0a;
}

.text-state-success {
  color: #397839;
}

.sapp-explanations {
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2.75rem;
  background-color: rgb(249 249 249);
}

.sapp-pointer-events-none {
  pointer-events: none;
}

.sapp-select--selectword-preview {
  width: 110px;
  min-width: 78px;
  border-radius: 0.25rem;
  --tw-ring-color: unset;
  --tw-ring-offset-shadow: unset;
  --tw-ring-shadow: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #f9f9f9;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 13px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  padding-right: 1.5rem;
  padding-left: 0.5rem;
  height: 35px;
  margin-bottom: 8px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="non"><path d="M10.9827 0.571289H9.97827C9.90996 0.571289 9.84568 0.604771 9.8055 0.659682L6.00059 5.90433L2.19568 0.659682C2.1555 0.604771 2.09121 0.571289 2.02291 0.571289H1.01845C0.931392 0.571289 0.880499 0.670396 0.931392 0.741379L5.65371 7.25165C5.82514 7.48736 6.17603 7.48736 6.34612 7.25165L11.0684 0.741379C11.1207 0.670396 11.0698 0.571289 10.9827 0.571289Z" fill="%23DCDDDD" /></svg>');
}

.sapp-select-confirmed {
  background-image: none;
  text-align: center;
  padding: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249);
}

.sapp-border-success {
  border: 1px solid #397839 !important;
}

.sapp-border-danger {
  border: 1px solid #b90e0a !important;
}

.text-bw-1 {
  color: #404041;
}

.fillText {
  margin-bottom: 50px;
}

.sapp-input-preview {
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  padding: 8px;
  width: 110px;
  height: 35px;
  margin-bottom: 4px;
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
