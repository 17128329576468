.sapp-custom-background-layout {
  background-color: var(--bs-gray-100) !important;
}

.sapp-min-h-100vh {
  min-height: 100vh !important;
}

.sapp-scrollbar {
  float: left;
  height: 300px;
  overflow-y: scroll;
  margin-bottom: 25px;
}

#kt_app_page::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--bs-gray-100);
  background-color: var(--bs-gray-100);
}

#kt_app_page::-webkit-scrollbar {
  width: 6px;
  background-color: var(--bs-gray-100);
}

#kt_app_page::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
}
