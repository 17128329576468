$white: #fff;
$bw-black-1: #404041;
$success: #397839;
$error: #b90e0a;
$gray-1: #a1a1a1;
$gray-2: #dcdddd;
$gray-3: #f1f1f1;
$background: #f9f9f9;
$primary: #ffb800;
$neutral5: #edeff5;
$neutral6: #f9fafc;
$icon-bg: #fffaf0;

.sapp-color-gray-a1 {
  color: $gray-1;
}

.sapp-text-hover-grade-primary:hover {
  color: $primary !important;
}

.sapp-text-remove-grade-primary:hover {
  color: $error !important;
}

.sapp-border-grade-1 {
  border: 1px solid $gray-1;
}

.sapp-border-grade-2 {
  border: 1px solid $gray-2;
}

.sapp-grade-page {
  font-family: 'Roboto';

  .sapp-layout-header-grade {
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
    z-index: 30;
  }

  .sapp-download-file:hover svg {
    color: $primary !important;
  }

  .sapp-remove-file:hover svg {
    color: $error !important;
  }

  .sapp-tabslides-wrapper {
    background: $background;
    display: flex;
    align-items: center;
  }

  .sapp-tabslides-container {
    padding: 8px 24px;
    flex: 1;
    max-width: 100%;
  }

  .sapp-tabslides-inner {
    width: 100%;
    overflow-x: auto;
  }

  .sapp-tabslides {
    display: flex;
    align-content: start;
    padding: 0;
    gap: 8px;
    margin-bottom: 0;
    list-style: none;

    li {
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border: 1px solid $gray-2;
      color: $gray-2;
      font-size: 14px;
      cursor: pointer;

      &.graded {
        color: $success;
        border-color: $success;

        &.viewed,
        &:hover {
          color: $white;
          background: $success;
        }
      }

      &.incorrect {
        color: $error;
        border-color: $error;

        &.viewed,
        &:hover {
          color: $white;
          background: $error;
        }
      }

      &.viewed,
      &:hover {
        color: $white;
        background: $gray-2;
      }
    }

    &-grid {
      height: 72px;
      max-height: 72px;
      flex-flow: column wrap;
    }
  }

  .filter {
    display: none;
    flex-direction: column;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 24px;
    min-height: 88px;

    &.active {
      display: flex;
    }

    input,
    label {
      cursor: pointer;
    }
  }

  .sapp-select-question {
    .form-check-input {
      border-radius: 0;
      border: 1px solid $gray-1;
      margin-right: 8px;
      width: 16px;
      height: 16px;

      &:checked {
        border-color: $primary;
      }
    }

    label {
      font-size: 14px;
      font-weight: 400;
      color: $gray-1;

      &.checked {
        color: $primary;
      }
    }
  }

  .sapp-question-wrapper {
    max-width: 1402px;
    width: 100%;
    margin: 24px auto;
    border: 1px solid $gray-3;
    background: $white;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

    &.sapp-layout-essay {
      max-width: 100%;
    }

    .sapp-question-header {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 4px;
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 400;
      color: $bw-black-1;
      background: $gray-3;

      strong {
        font-weight: 600;
        margin-right: 12px;
      }

      .question .incorrect {
        color: $error;
      }

      .question::after,
      .percentage::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 11px;
        background: $gray-1;
        margin: auto 12px;
      }

      .percentage svg {
        margin-right: 8px;
      }

      .view-solution {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $bw-black-1;
        text-decoration: underline;
      }

      .grade {
        .border-right {
          border-right: 0.1px solid $bw-black-1;
          height: 12px;
          margin: 0 10px;
        }

        strong {
          min-width: 24px;
          margin-left: 3px;
        }
      }
    }
  }

  .question-content {
    padding: 32px 32px 40px;

    .content {
      margin-bottom: 12px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $bw-black-1;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .sapp-matching-question {
      max-width: 654px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .matching-arrowed-container {
        position: relative;
        display: flex;
        height: auto;
        width: 50%;
        align-items: center;
        justify-content: flex-start;
        overflow-wrap: break-word;
        border: 1px solid $neutral5;
        padding: 20px 32px 20px 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $bw-black-1;
        background: $neutral6;
        word-break: break-word;

        &.incorrects,
        &.ungraded {
          color: $error;
          border-color: $error;

          &::before {
            border-color: $error;
          }
        }

        &.correct-answer {
          color: $success;
          border-color: $success;

          &::before {
            border-color: $success;
          }
        }

        &::before {
          content: '';
          right: -1px;
          width: 30px;
          height: 30px;
          transform: translate(50%) rotate(-135deg);
          position: absolute;
          z-index: 10;
          border: 1px solid $neutral5;
          border-width: 0 0 1px 1px;
          background: $neutral6;
        }
      }

      .match-result {
        display: flex;
        height: auto;
        width: 50%;
        align-items: center;
        justify-content: flex-start;
        overflow-wrap: break-word;
        border: 1px solid $neutral5;
        padding: 12px 16px 12px 43px;
        border: 1px solid $neutral5;
        border-width: 1px 1px 1px 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $bw-black-1;
        background: $neutral6;
        word-break: break-word;

        &.incorrects {
          color: $error;
          border-color: $error;
        }

        &.correct-answer {
          color: $success;
          border-color: $success;
        }
      }
    }

    .sapp-input-dragNDrop-answer {
      margin: 0.25rem;
      display: inline-block;
      min-width: 100px;
      border: 1px solid $neutral5;
      background-color: $neutral6;
      padding: 0.25rem;

      &.wrongs {
        color: $error;
        border-color: $error;
      }

      &.corrects {
        color: $success;
        border-color: $success;
      }
    }
  }

  .sapp-no-question {
    font-size: 14px;
    line-height: 24px;
    color: $gray-1;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    text-align: center;
  }

  .sapp-column-left-essay {
    max-height: calc(100vh - 96px);
    height: 100%;
    width: calc(100% - 470px);
  }

  .question-comment {
    display: flex;
    position: sticky;
    top: 0;
    height: auto;
    max-height: calc(100vh - 96px);
    overflow-y: auto;
    overflow-anchor: none;

    .question-container {
      flex: 1;
    }

    .comment-wrapper {
      padding: 32px;
      width: 470px;
      height: max-content;
      min-height: 100%;
      background: $white;
      box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.08);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      overflow-y: auto;

      .grade-box {
        padding: 16px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        width: 100%;

        label {
          line-height: 17px;
          margin-bottom: 4px;
          font-weight: 400 !important;
        }

        .tox.tox-tinymce {
          border-radius: 0 !important;
        }

        .tox-statusbar {
          display: none;
        }

        input {
          border-radius: 0 !important;
          border-color: $gray-2;
        }
      }
    }

    .grade-box-action {
      .button-cancel {
        background: transparent !important;
        padding: 0 !important;
        text-decoration: underline;
        line-height: 24px;
        height: auto !important;
        color: $bw-black-1;
      }

      .btn-primary {
        border-radius: 0;
        padding: 0 16px !important;
        min-width: auto !important;
        height: 32px !important;
      }
    }

    .comment ~ .grade {
      margin-top: 16px;
      border-top: 1px solid $gray-2;
      padding-top: 16px;
    }

    .comment-grade {
      display: flex;
      flex-direction: column;
      background: $background;
      padding: 16px;
      width: 100%;
      position: relative;
      outline: none;

      &:focus {
        background: $white;
        box-shadow: 0px 8px 16px 0px #00000014, 0px 0px 4px 0px #0000000a;
      }

      &:hover {
        background: $white;
        box-shadow: 0px 8px 16px 0px #00000014, 0px 0px 4px 0px #0000000a;
        cursor: pointer;

        .edit-delete {
          display: flex;
        }
      }

      label {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $bw-black-1;
      }

      .comment {
        label {
          margin-bottom: 8px;
        }

        .content {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: $bw-black-1;

          p:last-child {
            margin-bottom: 0;
          }
        }
      }

      .grade span {
        color: $success;
      }

      .edit-delete {
        position: absolute;
        top: 16px;
        right: 16px;
        gap: 8px;
        display: none;

        .edit,
        .delete {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          border-radius: 2px;
          background: $gray-3;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        }

        .edit {
          svg {
            width: 14px;
            height: 14px;
          }

          &:hover path {
            fill: $primary;
          }
        }

        .delete {
          color: $gray-1;

          &:hover {
            color: $error;
          }
        }
      }
    }
  }

  .ant-skeleton .ant-skeleton-paragraph {
    margin: 0;

    > li {
      height: 32px;
    }
  }

  .open .loading .ant-skeleton .ant-skeleton-paragraph > li {
    height: 72px;
  }
}

.grade-header-icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border: 1px solid $white;
  color: $white;
  margin-left: 8px;
  cursor: pointer;

  &.open-grid {
    background: $white;
    color: $bw-black-1;
  }
}

.icon-action {
  position: relative;
  cursor: pointer;

  ul {
    display: none;

    li {
      span,
      svg {
        display: block;
      }
    }
  }

  .open-action {
    background: $white;
    color: $bw-black-1;
  }

  .open-action-drop-down {
    display: block;
    position: absolute;
    top: 100%;
    margin-top: 4px;
    right: -1px;
    padding: 8px;
    background: $white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid var(--bw-white, $white);
    list-style: none;
    width: max-content;
    cursor: initial;
    z-index: 20;

    li {
      color: $bw-black-1;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16.9px;
      margin-top: 4px;
      padding-top: 4px;
      border-top: 1px solid $gray-3;
      cursor: pointer;

      &:first-child {
        margin: 0;
        padding: 0;
        border: 0;
      }

      svg {
        color: $gray-1;
      }

      &:hover,
      &:hover svg {
        color: $primary;
      }
    }
  }
}

.modal-dialog.finish-alert-model {
  --bs-modal-width: calc(614px + 3rem);
  border: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &.loading {
    .modal-content {
      --bs-modal-bg: transparent;
      --bs-modal-box-shadow: none;
    }
  }

  .modal-content {
    --bs-modal-border-radius: 0;
    padding: 70px;
  }

  .icon-container {
    background-color: $icon-bg;
  }

  .alert-title {
    font-family: Roboto;
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
    color: $bw-black-1;
    margin: 0;
    margin-top: 1.846rem;
  }

  .alert-desc {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.9px;
    color: $gray-1;
    margin: 0;
    margin-top: 1.231rem;

    .questions {
      color: $bw-black-1;
    }
  }

  .alert-actions {
    padding-left: 6px;
    padding-right: 6px;

    button {
      color: $white;
      background-color: $primary;
      font-family: 'Roboto';
      font-size: 16px;
      line-height: 24px;
      padding: 13px !important;

      &.btn-continue,
      &.btn-close-modal {
        margin-top: 4.923rem;
        padding: 14px !important;
      }

      &.btn-continue {
        color: $white;
      }

      &.btn-continue:hover {
        background-color: $primary !important;
        opacity: 0.7;
      }

      &.btn-close-modal {
        color: $primary;
        background-color: $white;
      }

      &.btn-retry {
        margin-top: 1rem;
      }
    }
  }

  .ant-spin .ant-spin-dot-holder {
    color: $primary;
  }
}

.grade-bg-1 {
  background: $background;
}

.grade-bg-2 {
  background: $bw-black-1;
}

.grade-lh-27 {
  line-height: 27px;
}

.grade-px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.grade-py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sapp-input-dragNDrop-empty {
  display: inline-block;
  min-width: 100px;
  border-width: 0px;
  text-align: center;
}

.topic-resource,
.topic-requirement {
  .sapp-text-gray-800:hover {
    color: $primary !important;
  }
}

.grade-requirement {
  &.inactive {
    box-shadow: 0px 2px 4px 0px #00000014, 0px 0px 6px 0px #00000005;
    opacity: 0.4;
  }
}
.grade-unfinish {
  .sapp-grade-questions-content {
    ::selection {
      background-color: #f9b402;
      color: $bw-black-1;
    }
  }
}

.sapp-grade-questions-content {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &.table-content {
    max-height: 500px;

    table {
      border-color: $gray-2;
      width: max-content;
    }
  }
}

.sapp-comment-highlight {
  position: relative;
  background-color: #fff1cc;
}

.sapp-grading-highlight {
  position: relative;
  text-decoration: underline $success;
}

.sapp-comment-highlight *,
.sapp-grading-highlight * {
  user-select: none;
}

.sapp-tooltip-grade {
  background: $white;
  display: flex;
  z-index: 1000;
  box-shadow: 2px 5px 8px 0px #0000002b;

  .sapp-grade-action {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-1;
    font-size: 13px;
    font-weight: 500;
    line-height: 16.9px;
    border: none;
    background: transparent;
    padding-top: 12px;
    padding-bottom: 12px;

    &.edit-icon::after {
      content: '';
      display: block;
      width: 1px;
      height: 16px;
      background: $gray-2;
      margin-left: 16px;
    }

    &:hover {
      color: $primary;

      .ki-outline {
        color: $primary;
      }
    }

    &:first-child {
      padding-left: 16px;
      padding-right: 0;
    }

    &:last-child {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.sapp-grade-tooltip {
  position: absolute;
  bottom: 24px;
  height: 30px;
  min-width: 30px;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  background-color: $success;
  color: $white;
  padding: 7px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  z-index: 20;

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    width: 14px;
    height: 7px;
    border-style: solid;
    border-color: $success transparent transparent transparent;
  }
}

@media (max-width: 1280px) {
  .layout-left {
    overflow: auto;
  }

  .essay-d-flex {
    flex-wrap: wrap;

    .sapp-column-left-essay {
      width: 100%;
      max-height: 100%;
      overflow: unset;
    }

    .question-comment {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;

      .comment-wrapper {
        width: 100%;
      }
    }
  }
}

@media (max-width: 769px) {
  .sapp-grade-page {
    .grade-header {
      flex-direction: column;
      gap: 8px;

      .grade-header-icon-wrapper {
        align-self: flex-end;
      }
    }

    .sapp-question-header {
      flex-direction: column;
    }

    .question-essay {
      flex-direction: column;
      min-height: auto;
    }

    .question-comment .comment-wrapper {
      padding: 16px;
    }
  }

  .modal-dialog.finish-alert-model {
    .modal-content {
      padding: 40px;
    }
  }
}
