.sapp-required-dot {
  color: #f14c41;
  // font-family: Roboto;
  font-size: 13.975px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.962px;
  margin-left: 2px;
  /* 150% */
}

.sapp-addVideo-area {
  height: 470px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  // border: 1px solid #E1E3EA;
  background: var(--bs-gray-100);
  // box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.03);

  &.video-selected {
    justify-content: unset;
  }

  .sapp-addVideo-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }
}

.sapp-video-selected-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  flex-direction: column;
}

.sapp-tab-btn-video {
  width: 79px;
  height: 79px !important;

  &:hover {
    .sapp-icon-btn-tab {
      color: var(--bs-primary) !important;
    }

    .sapp-title-video-setting {
      color: var(--bs-primary);
    }
  }

  &.active {
    .sapp-title-video-setting {
      color: var(--bs-primary);
    }
  }
}

.sapp-icon-btn-tab {
  color: #ccced6 !important;
}

.sapp-title-video-setting {
  color: #9094a5;
  text-align: center;
  // font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 109.091% */
  letter-spacing: 0.115px;
}

.sapp-video-setting-content-container {
  padding-left: 80px;
}

.sapp-added-question-container {
  display: flex;
  width: 35%;
  background: white;
  border-right: 1px solid #e1e3ea;
  border-bottom: 1px solid #e1e3ea;

  .sapp-title-video-setting {
    display: flex;
  }
}

.sapp-list-question-added-container {
  max-height: 70%;
  overflow: auto;
}

.sapp-input-menuVideo {
  border-top: none;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 !important;
}

.sapp-question-name {
  max-width: 100%;
  // overflow: hidden;
  color: var(--bs-gray-800);

  // text-overflow: ellipsis;
  // white-space: nowrap;
  // font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  // line-height: 24px;
  /* 27.2px */
}

.sapp-text-time {
  color: var(--bs-gray-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.1px;
  /* 115% */
}

.sapp-questionTab-title {
  color: var(--bs-gray-800);
  // font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-self: center;
  /* 133.333% */
}

.sapp-tabside-container {
  border-right: 1px solid #e1e3ea;
}

.sapp-video-container {
  // display: flex;
  padding: 10px;
  width: 70%;
  // border-bottom:  1px solid #E1E3EA;
  background: #f7f7f8;

  .ui-video-seek-slider .track .main .connect {
    background-color: var(--bs-primary);
  }

  .ui-video-seek-slider .thumb .handler {
    background-color: var(--bs-primary);
  }
}

.sapp-video-uploaded-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.sapp-modal-body-cutsom {
  .sapp-modal-body-content {
    height: 100%;
  }
}

.sapp-title-alert {
  color: var(--bs-gray-800);
  // font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
}

.sapp-content-alert {
  color: #78829d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  /* 16.9px */
}

.sapp-w-40px {
  width: 40px !important;
}
