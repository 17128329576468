.sapp-radio-image-group {
  .sapp-form-check {
    display: block;
    position: relative;
    cursor: pointer;
  }

  .sapp-form-check:not(:last-child) {
    margin-right: 3rem;
  }

  .sapp-form-check-input {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }

  .sapp-form-check-label {
    margin-left: 0;
  }

  .sapp-label-text {
    margin-top: 10px;
  }

  .sapp-font-radio-image {
    font-size: 13px !important;
    line-height: 22.5px !important;
  }
}
