.sapp-certificate {
  background-color: #ebebeb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  height: 100%;
}
.sapp-certificate-header,
.sapp-certificate-taskbar {
  flex: 0 0 auto;
}
.sapp-certificate-header > div {
  padding-top: 11px;
  padding-bottom: 11px;
}
.sapp-certificate-taskbar {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 600 !important;
  min-height: 70px;

  &_left {
    display: flex;
    gap: 5px;
  }
  &_font-family,
  &_tag {
    width: 130px !important;

    .ant-select-selection-item {
      font-weight: 400 !important;
      color: #222f3e;
    }
    &:hover .ant-select-selector {
      border-color: var(--bs-gray-300) !important;
    }
  }
  &_tag {
    .ant-select-selection-item > * {
      line-height: 18.62px !important;
      font-size: 14px !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  &_font-family {
    .ant-select-selection-item > * {
      font-family: 'Be Vietnam Pro', 'Inter' !important;
    }
  }
  &_font-style {
    display: flex;
    gap: 2px;
    align-items: center;
  }
  &_text-color {
    position: relative;
    .sapp-color-picker-input {
      opacity: 0 !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
  &_font-format,
  &_tool {
    display: flex;
    gap: 2px;
    align-items: center;
  }
  &_effect,
  &_animation {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &_animation {
    position: relative;
    &-left {
      margin-right: 3px;
      margin-top: -3px;
    }
    &-right {
      position: absolute;
      top: -3px;
      right: -9px;
    }
  }
}
.sapp-certificate-content {
  flex: 0 1 auto;
}

.resizable {
  background: white;
  position: absolute;
  top: 100px;
  left: 100px;
  cursor: all-scroll;
}
.resizable .resizers {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: none;
  z-index: 500;
}
.resizable.enable .resizers {
  display: block !important;
  border: 2px solid #7e8299;
}
.resizable .resizers .resizer {
  width: 12px;
  height: 12px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;
  position: absolute;
  box-shadow: 0px 0px 0px 1px rgba(43, 59, 74, 0.3), 0px 0px 4px 1px rgba(57, 76, 96, 0.15);
  z-index: 500;
}

.resizable .resizers .resizer.top-left {
  left: -5px;
  top: -5px;
  cursor: nwse-resize; /*resizer cursor*/
}
.resizable .resizers .resizer.top-right {
  right: -5px;
  top: -5px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-left {
  left: -5px;
  bottom: -5px;
  cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-right {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;
}
.resizable .resizers .resizer.right {
  width: 6px;
  height: 18px;
  right: -2.5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: col-resize;
  border-radius: 3px;
}
.resizable .resizers .resizer.left {
  width: 6px;
  height: 18px;
  left: -2.5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: col-resize;
  border-radius: 3px;
}

.sapp-opacity-bg-border {
  background: transparent;
  border: none;
  resize: none;

  &:focus {
    background: transparent;
  }

  &.enable .sapp-trash-certificate {
    display: block !important;
  }
}

.sapp-trash-certificate {
  right: 0;
  top: -36px;
  padding: 2px;
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.sapp-width-max {
  max-width: max-content;
}

.sapp-input-add-certificate {
  height: 38px !important;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;

  &.btn-primary:disabled,
  &.btn-primary:disabled:hover {
    background: #f1f1f2 !important;
    color: #7e8299 !important;

    i {
      color: #7e8299 !important;
    }
  }
}

.sapp-h-774px {
  height: 774px !important;
}

.sapp-alight-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  width: fit-content;

  &.sapp-image-scale-08 {
    transform: translate(-50%, -50%) scale(0.8) !important;
  }

  &.sapp-image-scale-05 {
    transform: translate(-50%, -50%) scale(0.5) !important;
  }
}

.sapp-alight-center-detail {
  position: relative;
  width: fit-content;
}

.sapp-modal-dialog-preview {
  .modal-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .sapp-modal-header-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  .sapp-p-body-content {
    margin: 0 !important;
    overflow: hidden;
  }
}

@media (min-width: 1300px) {
  .sapp-modal-dialog-preview {
    max-width: 60% !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sapp-modal-dialog-preview {
    max-width: 80% !important;
  }
}

.sapp-no-white-space {
  white-space: nowrap;
}

.sapp-table-certificate {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;

  .sapp-image-certificate {
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.18);
  }
}

.sapp-item-certificate {
  border-radius: 8px;
  border: 1px solid #e1e3ea;
  background: #f9f9f9;
  overflow: hidden;
  width: 283px;
  height: 156px;

  &.enable {
    border: 1px solid #ffb800;

    .sapp-custom-dot {
      border: 6px solid #ffb800;
      background-color: #ffffff;
    }
  }

  &.disable {
    .sapp-custom-dot {
      background-color: #e1e3ea;
    }
  }
}

.sapp-custom-dot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 8px;
  right: 8px;
  z-index: 50;
}

.sapp-h-fit-content {
  height: fit-content;
}

.sapp-custom-table-certificate {
  &:not(.modal-fullscreen) {
    border: none;
  }

  .card-body {
    padding: 34px 32px;
  }

  .card-footer {
    border: none;
  }
}

.sapp-max-image-default {
  max-width: 1200px;
  max-height: 1200px;
}

.sapp-certificate-taskbar_font-style {
  .sapp-certificate-taskbar_checkbox.true {
    background: #f7f7f7;
  }
  svg path {
    fill-opacity: 1;
  }
}

.sapp-certificate-taskbar_tag,
.sapp-certificate-taskbar_font-family {
  .ant-select-selector {
    background: #f7f7f7 !important;
    // height: 28px !important;
  }
  // .ant-select-selection-search-input {
  //   height: 28px !important;
  // }
}

.sapp-max-w-283 {
  max-width: 283px;
}

.sapp-font-change-image {
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  top: -32px;

  span {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  &.sapp-course-change {
    position: absolute;
    right: 0;
    top: -32px;
    width: max-content;
  }
}

.sapp-min-w-160px {
  min-width: 160px;
}

.resizable.moveable-resizable {
  textarea {
    cursor: pointer;
  }

  &.enable textarea {
    cursor: text;
  }
}

.btn.btn-secondary {
  background-color: #f9f9f9 !important;
  color: var(--bs-gray-800) !important;
  // font-size: 20px !important;
}

.btn.btn-secondary:hover:not(.btn-active) {
  background-color: #f9f9f9 !important;
}

.btn.btn-secondary.sapp-btn-custom {
  font-size: 13px;
}

.sapp-text-certificate {
  font-size: 14px;
  line-height: 21px;
  color: #99a1b7;
  font-weight: 500;
}
