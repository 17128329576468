@import '../../../styles/index.scss';
@import '../Exam.module.scss';

.tableHeader {
  width: 50px;
}

.table {
  width: 1100px;
  min-width: 100%;

  .actionCellCol:last-child {
    background-color: $gray-100;
    right: 0;
  }

  th,
  td {
    padding-right: 20px;
  }
}

.tableLoading {
  width: 100%;
  height: 100%;
  position: relative;
  clear: both;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
  cursor: progress;
}

.spin {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  :global(.ant-spin-dot-item) {
    color: $primary;
  }
}

.createDeleteButtons {
  gap: 12px;
}

.examLink {
  &:hover {
    color: $primary;
  }
}

.deleteErrorContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.deleteError {
  p {
    margin: 0;
  }

  &:not(:last-child) {
    padding-bottom: 5px;
    border-bottom: 1px solid $gray-300;
  }
}
