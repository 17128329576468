.topic_description {
  .btn.btn-light {
    font-size: 12px !important;
    line-height: 12px;
    padding: 7px 10px !important;
  }

  div.text-inverse-light {
    font-size: 12px !important;
    line-height: 14px;
  }
}
