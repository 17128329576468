//
// Variables
//

// Custom
$app-separator-border: 1px solid #1C2039;
$app-separator-border-dark: 1px solid $gray-300-dark;

// Reboot
$app-bg-color: $body-bg;
$app-bg-color-dark: $body-bg-dark; 
$app-blank-bg-color: $body-bg;
$app-blank-bg-color-dark: $app-bg-color-dark;

// General
$app-general-root-font-size-desktop: 13px;
$app-general-root-font-size-tablet: 12px;
$app-general-root-font-size-mobile: 12px;

// Header base
$app-header-base-height: 100px;
$app-header-base-height-mobile: 70px;
$app-header-base-bg-color: $body-bg;
$app-header-base-bg-color-dark: $body-bg-dark;

// Header minimize
$app-header-minimize-height: 100px;
$app-header-minimize-height-mobile: 70px;
$app-header-minimize-bg-color: rgba($body-bg, 0.5);
$app-header-minimize-bg-color-dark: rgba($body-bg-dark, 0.5);
$app-header-minimize-backdrop-filter: blur(8px);
$app-header-minimize-backdrop-filter-mobile: blur(8px);

// Sidebar base
$app-sidebar-base-width: 340px;
$app-sidebar-base-width-mobile: 295px; 
$app-sidebar-base-bg-color: #111424;
$app-sidebar-base-bg-color-dark: #111424;
 
// Sidebar primary
$app-sidebar-primary-base-width: 82px;
$app-sidebar-primary-base-width-mobile: 60px;
$app-sidebar-primary-base-bg-color: #111424;
$app-sidebar-primary-base-bg-color-dark: #111424;

// Sidebar primary menu
$app-sidebar-primary-menu-size: 42px;

// Toolbar sticky
$app-toolbar-sticky-z-index: 99;
$app-toolbar-sticky-height: 70px;
$app-toolbar-sticky-bg-color: $body-bg;
$app-toolbar-sticky-bg-color-dark: #1e1e2d;
$app-toolbar-sticky-box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05);
$app-toolbar-sticky-box-shadow-dark: none;

// Content
$app-content-padding-y: 0;
$app-content-padding-y-mobile: 0;

// Footer
$app-footer-height: 60px;
$app-footer-height-mobile: auto;

// Scrolltop
$scrolltop-bottom: 43px;
$scrolltop-bottom-mobile: 23px;
$scrolltop-end: 7px;
$scrolltop-end-mobile: 5px;

// Layout builder toggle(used for Demo purposes only)
$app-layout-builder-toggle-bg-color: $body-bg;
$app-layout-builder-toggle-end: 50px;
$app-layout-builder-toggle-end-mobile: 40px;
$app-layout-builder-toggle-bottom: 40px;
$app-layout-builder-toggle-bottom-mobile: 20px; 