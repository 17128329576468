.editAndCreateBtn {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .iconButton {
    width: 30px;
    height: 30px;
    background-color: #f1f1f2;
    border: none;
    border-radius: 4px; /* Optional: for circular button */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:hover {
      background-color: #dddddd;
    }
  }
}
