.modal-dialog:not(.modal-fullscreen) {
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
}

.sapp-modal-header-title {
  font-size: 18px;
  color: var(--bs-gray-800);
  font-weight: 600;
}

.modal-fullscreen.modal-fullscreen-form {
  .modal-header {
    padding: 0 !important;
    max-width: 1200px;
    margin: auto;
    width: 100%;

    & > .sapp-modal-header-title {
      padding: 20px 32px !important;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      font-size: 24px;
      color: var(--bs-gray-800);
      font-weight: 600;
      line-height: 30px;
      /* 100% */
    }

    .sapp-btn-close-modal {
      position: absolute;
      right: 0;
      padding: var(--bs-modal-header-padding) !important;
    }
  }

  .modal-header {
    transform: translateX(-0px);
  }

  .modal-body {
    padding: 0 !important;
    display: block;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;

    & > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin: 0 !important;
      margin-right: 0 !important;
    }

    & .sapp-modal-body-content {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: var(--bs-modal-header-padding) !important;
      padding-left: 32px !important;
      padding-right: 32px !important;
    }
  }

  .modal-footer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: var(--bs-modal-header-padding) !important;
  }

  .sapp-mw-900px.modal-footer {
    padding-right: 0 !important;
  }
}

.sapp-drawer-title {
  color: var(--bs-gray-800);

  /* Body/B-18-18-600 */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 100% */
  letter-spacing: -0.18px;
}

.sapp-btn-close-modal {
  position: relative;
  bottom: 3px;
  right: 0px;
}

.modal-backdrop {
  background-color: #000000b2 !important;
  opacity: 1 !important;
}

.sapp-mw-900px {
  max-width: 900px !important;
}

.sapp-mw-900px.sapp-border-top-unset {
  border-top: unset;
}

.sapp-mh-500px {
  height: 500px;
}

.sapp-mh-500px {
  height: 500px;
}

.ant-drawer-body {
  font-family: Inter, Helvetica, 'sans-serif' !important;
}

.modal-maillog {
  border-radius: var(--bs-modal-border-radius);
}

.sapp-min-w-650px {
  min-width: 600px;
}

.modal-body::-webkit-scrollbar {
  width: var(--bs-scrollbar-size) !important;
  background-color: var(--bs-primary) !important;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary) !important;
}

@media (min-width: 992px) {
  .modal-fullscreen.modal-fullscreen-form {
    .modal-body {
      scrollbar-width: thin;
      scrollbar-color: var(--bs-primary) transparent;
      right: -4px;
      &::-webkit-scrollbar {
        width: var(--bs-scrollbar-size) !important;
        background-color: var(--bs-white) !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--bs-primary);
      }
    }
  }
}

.sapp-min-w-550px {
  min-width: 550px;
}
