.modal-dialog__edit-email {
  .modal-content {
    max-width: 414px;
  }
}

.modal-header {
  &__edit-email {
    display: flex;
    justify-content: space-between;
    padding: 27px 32px;
  }
}

.modal-body {
  &__edit-email {
    padding: 32px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .text-danger {
    position: absolute;
  }
}

.otp {
  &__resend-duration {
    color: #3964ea;
  }

  &__email-guide {
    display: block;
    color: $gray-600;
    margin-bottom: 0;

    strong {
      font-weight: 500;
    }
  }

  &__resend-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    color: $gray-600;
  }

  &__outer-input {
    width: 14%;
    padding-bottom: 14%;
    position: relative;
  }

  &__inner-input {
    position: absolute !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100% !important;
    outline: none;
    transition: border-color 0.3s;
    background-color: #f5f5f5;
    border-radius: 0.65rem;
  }
}

.card-footer {
  &__otp {
    border-top: 1px solid #f1f1f4;
    justify-content: center;
  }
}
