@import '../../../styles/index.scss';
.trashExamDate {
  display: flex;
  justify-content: end;
  align-items: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
