.sapp-btn-action-cell {
  &_svg {
    fill: #7e8299 !important;
  }

  &:hover &_svg {
    fill: var(--bs-primary);
  }
}

.sapp-menu-shadow {
  right: 17px !important;
  box-shadow: 1px 2px 11px 0px rgb(223, 223, 223);
}

.menu-sub-dropdown {
  margin-top: 1.1rem !important;
}
