.sapp-btn--secondary {
  background-color: var(--bs-gray-100) !important;
  font-size: 14px;
  color: var(--bs-gray-600);
  font-weight: 500;
  line-height: 16px;
}

.sapp-btn--secondary:hover {
  background-color: var(--bs-light) !important;
  color: var(--bs-gray-600);
}

.sapp-btn--secondary:active {
  background-color: var(--bs-light) !important;
  color: var(--bs-gray-600) !important;
}
