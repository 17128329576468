.sapp-preview-video {
  min-width: 860px;
  max-height: 600px;

  .video-js {
    max-width: 100%;
    max-height: 575px;

    &.vjs-fullscreen {
      max-height: 100%;
    }
  }
}

.sapp-wrapper {
  width: 100%;
  position: relative;
  padding-top: calc((9 / 16) * 100%);

  iframe {
    width: 100%;
    height: 100%;
  }
}

.sapp-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sapp-wrapper-video {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin: auto;
}
