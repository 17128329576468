.sapp-upload-file {
  &-progress {
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    padding: 15px 20px;
    border-radius: 6px;
    margin-top: 20px;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .ant-progress {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &_icon {
      margin-right: 15px;
    }

    .ant-progress-outer {
      order: 2;
      padding-top: 15px;
    }

    .ant-progress-text {
      margin-right: calc(2em + 8px);
      margin-left: auto;
      order: 1;
    }
  }
}

.sapp-upload-file-content {
  position: relative;

  .sapp-upload-file-name {
    position: absolute;
    top: -4px;
    left: 0;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.sapp-modal_upload-file {
  .modal-header {
    padding-bottom: 0;
    align-items: start;
  }
}
.sapp-modal_upload-file_tab_title {
  cursor: pointer;
  &._checked {
    color: #141414;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: var(--bs-primary);
      width: 100%;
      bottom: -1px;
      left: 0;
    }
  }
}

.sapp-mw-900px {
  max-width: 900px;
}

.sapp-py-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.sapp-text-secondary {
  color: #99a1b7;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 16px;
}

.sapp-mh-350px {
  max-height: 350px;
}

.sapp-py-140px {
  padding-top: 140px;
  padding-bottom: 140px;
}

.sapp-h-500px {
  height: 500px;
}

.sapp-p-content.sapp-modal-body-content .ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
