.sapp-course_radio-text-field-group {
  width: 100%;
  .sapp-hook-form-radio-group_item:first-child {
    min-width: 170px;
  }
  .sapp-radio-text-field_input {
    width: 310px;
  }
  .sapp-hook-form-radio-group div:last-child {
    flex: 1;
  }
}

@media only screen and (max-width: 1400px) {
  .sapp-radio-text-field_input {
    width: 100% !important;
  }
}
