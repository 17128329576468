.sapp-question-type {
  width: 25%;
  min-width: 169px;
  max-width: 126px;

  &_content {
    padding: 1.2rem;
    border-radius: 4px;

    &:hover {
      & > .sapp-question-type_name {
        color: var(--bs-primary);
      }

      & > .sapp-question-type_img {
        display: none;
      }

      & > .sapp-question-type_img__hovered {
        display: block;
      }
    }
  }

  &_img__hovered {
    display: none;
  }

  &_name {
    text-align: center;
    color: #8f95b2;
    font-weight: 500;
    font-size: 15px;
  }
}
